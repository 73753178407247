<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="100" align="center"></el-table-column>
    <el-table-column :label="columName" prop="name" min-width="180">
      <template slot-scope="scope">
        <router-link class="text-primary" :to="getToShowRouter(scope.row)">{{ scope.row.name }}</router-link>
      </template>
    </el-table-column>
    <el-table-column v-if="['attraction', 'museum', 'lookup'].includes(type)" label="Loại" prop="created_at" width="200">
      <template slot-scope="scope">
        <el-tag v-if="!scope.row.parent_id" type="primary">{{ currentTab.name }} cha</el-tag>
        <el-tag v-else type="warning">{{ currentTab.name }} con</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="Ngày tạo" prop="created_at" width="100"></el-table-column>
    <el-table-column label="Thao tác" width="200" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Xóa" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="$emit('delete', scope.row)"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'ListContent',
  props: {
    data: Array,
    loading: Boolean,
    type: String,
    currentTab: Object,
    placeType: String
  },
  computed: {
    columName() {
      if (this.placeType === 'post') {
        return 'Tên bài viết'
      }
      if (this.placeType === 'tour') {
        return 'Tên Tour'
      }
      if (this.placeType === 'hierarchy') {
        return 'Tên phân hệ'
      }
      return 'Tên'
    }
  },
  methods: {
    getToShowRouter(row) {
      const firstText = this.type.slice(0, 1).toUpperCase()
      const type = `${firstText}${this.type.slice(1, this.type.length)}`
      if (this.placeType === 'hierarchy') {
        return { name: `${type}Detail`, params: { id: row.id }}
      }
      return { name: `${type}Info`, params: { id: row.id }}
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
