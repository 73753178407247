<template>
  <div v-if="allTabs.length" class="org-places shared-index">
    <h3 class="m-0">Phân quyền nội dung</h3>
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card">
          <el-tab-pane v-for="tab in allTabs" :key="tab.value" :name="tab.value">
            <div slot="label">
              {{ tab.name }}
              <span v-if="tab.value === activeTab">({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="visible = true"
        >Thêm nội dung</el-button>
      </div>
      <div class="table-title__right ml-2">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Tìm kiếm theo email nội dung"
          size="small"
          @input="handleSearch"
        ></el-input>
      </div>
    </el-row>
    <div v-loading="callingAPI" class="table">
      <list-contents
        :data="tableData"
        :loading="loading"
        :type="activeTab"
        :current-tab="currentTab"
        :place-type="placeType.type"
        @delete="handleDeletePlace"
      ></list-contents>
    </div>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <add-places-popup
      v-if="visible"
      :visible="visible"
      :organization="organization"
      :type="activeTab"
      :place-type="placeType.type"
      :current-tab="currentTab"
      @reload="handleReload"
      @close="visible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { updateOrganization } from '@/services/organizations'
import { getDataJs, getTranslation, getPlaceTypes, orgContents } from '@/utils/filters'
import ListContents from './contents/List'
import AddPlacesPopup from './popups/AddPlaces'

import TYPE from '@/data/type'

export default {
  name: 'OrgPlaces',
  components: {
    ListContents,
    AddPlacesPopup
  },
  props: {
    organization: Object
  },
  data() {
    return {
      loading: false,
      visible: false,
      activeTab: '',
      callingAPI: false,
      searchText: '',
      limit: 10,
      page: 1
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.activeTab) || {}
    },
    orgMenus() {
      return this.organization && this.organization.menus ? this.organization.menus.split(',') : []
    },
    allTabs() {
      return getPlaceTypes(false).filter((t) => this.orgMenus.includes(t.value))
    },
    currentTab() {
      return this.allTabs.find((t) => t.value === this.activeTab)
    },
    allOrgPlaces() {
      const places = orgContents('places', this.organization).filter((p) => p.place_type === this.activeTab)
      return places.map((p) => ({
        ...p,
        name: getTranslation(p).name
      }))
    },
    allOrgPosts() {
      const posts = orgContents('posts', this.organization)
      return posts.map((p) => ({
        ...p,
        name: getTranslation(p).name
      }))
    },
    allOrgTours() {
      const tours = orgContents('tours', this.organization)
      return tours.map((t) => ({
        ...t,
        name: getTranslation(t).name
      }))
    },
    allOrgHierarchies() {
      const hierarchies = orgContents('hierarchies', this.organization)
      return hierarchies.map((p) => ({
        ...p,
        name: getTranslation(p).name
      }))
    },
    orgTableData() {
      let orgData = this.allOrgPlaces
      if (this.placeType.type === 'post') {
        orgData = this.allOrgPosts
      } else if (this.placeType.type === 'tour') {
        orgData = this.allOrgTours
      } else if (this.placeType.type === 'hierarchy') {
        orgData = this.allOrgHierarchies
      }
      return orgData
    },
    tableData() {
      if (this.organization) {
        const data = getDataJs(this.orgTableData, this.page, this.limit, this.searchText, 'name')
        return data.data
      }
      return []
    },
    total() {
      if (this.organization) {
        const data = getDataJs(this.orgTableData, this.page, this.limit, this.searchText, 'name')
        return data.total
      }
      return 0
    }
  },
  watch: {
    currentTab: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (!this.currentTab) {
        this.activeTab = this.allTabs.length ? this.allTabs[0].value : ''
      }
    },
    handleChangePage(page) {
      this.page = page
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
    },

    handleSearch(text) {
      this.searchText = text
      this.page = 1
    },

    async handleReload() {
      this.loading = true
      await this.$emit('reload')
      this.loading = false
    },

    handleDeletePlace(place) {
      this.$confirm('Bạn có chắc chắn muốn nội dung khỏi tổ chức này không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        this.loading = true
        updateOrganization({
          id: this.organization.id,
          place_ids: orgContents('places', this.organization).filter((u) => u.id !== place.id).map((u) => u.id),
          tour_ids: this.allOrgTours.filter((u) => u.id !== place.id).map((u) => u.id),
          post_ids: this.allOrgPosts.filter((u) => u.id !== place.id).map((u) => u.id),
          hierarchy_ids: this.allOrgHierarchies.filter((u) => u.id !== place.id).map((u) => u.id)
        }).then(async () => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Xóa nội dung khỏi tổ chức thành công'
          })
          await this.$emit('reload')
          if (this.tableData.length === 1 && this.page > 1) {
            this.page = this.page - 1
          }
          this.loading = false
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Không thể xóa nội dung khỏi tổ chức'
          })
          this.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.org-places {
  .table-title {
    &__left {
      max-width: calc(100% - 200px);
      .el-tabs {
        max-width: 100%;
      }
    }
  }
}
</style>
